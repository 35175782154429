<template>
  <div class="header p_sticky">
    <div class="wrap">
      <div class="title flex-row flex-center">
        <router-link to="/" class="home">
          <img :src="logo" class="logo" />
          <span class="title-text"> 中医临床药学服务<br>与调剂教育平台 </span>
        </router-link>
        <ul class="nav-menu flex-row justify-evenly flex1">
          <li v-for="(item, index) in links" :key="index" :class="{ active: item.active }">
            <router-link :to="item.url" :class="{ active: item.url === '/trend' }">{{ item.name }}</router-link>
          </li>
        </ul>
        <Avatar style="margin-right: 37px;" />
      </div>
    </div>
  </div>
  <img class="swiper-img" style="display: block;width: 1200px;margin: 0 auto"
    :src="require('../pages/app/images/dynamic.jpg')" />
  <div style="background-color: white">
    <div class="wrap" style="padding-top: 2rem;">
      <div style="padding: 2rem;background-color: #f5f5f5">
        <span :class="!partId ? 'color_f3' : ''" style="margin-left: 20px; cursor: pointer"
          @click="() => load(null, sortType, true)">全部</span>
        <span :class="partId === item.id ? 'color_f3' : ''" style="margin-left: 50px; cursor: pointer"
          v-for="(item, index) in columns" :key="index" v-text="item.name"
          @click="() => load(item.id, sortType, true)"></span>
      </div>
      <div style="padding: 2rem">
        <span style="margin-left: 20px">筛选：</span>
        <span :class="sortType === item.value ? 'color_f3' : 'color_a5'" style="margin-left: 50px; cursor: pointer"
          v-for="(item, index) in filters" :key="index" v-text="item.label"
          @click="() => load(partId, item.value, true)"></span>
      </div>
      <div class="articles">
        <div v-for="(item, index) in articles" :key="index" class="article flex-row">
          <div class="time-line flex-column flex-center">
            <div class="time-line-dot"></div>
            <div class="time-line-line"></div>
          </div>
          <div class="content">
            <div class="color_a5" v-text="item.day"></div>
            <router-link :to="'/article/' + item.id" class="flex-row">
              <img :src="item.headImgUrl" class="article-cover" />
              <div class="title flex-column justify-center">
                <div class="ellipsis" style="font-weight: bold" v-text="item.articleName"></div>
                <div class="ellipsis2" style="font-size: 1rem" v-text="item.briefContent"></div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="flex-row flex-center" style="margin-bottom: 2rem">
        <div :class="{ loadmore: true, loading: !!loading, nomore: !hasmore }"
          v-text="hasmore ? (!!loading ? '正在加载' : '加载更多') : '没有了'" @click="() => load(partId, sortType, false)"></div>
      </div>
    </div>
  </div>
</template>

<script >
import { Options, Vue } from "vue-class-component";
import "swiper/css/swiper.min.css";
import { post, getLogindata } from "@/utils/common";
import moment from "moment";
const logo = require("../assets/logo.jpg");

@Options({
  components: {},
})
export default class Home extends Vue {
  logo = logo;

  articles = [];

  hasmore = true;

  loading = 0;

  size = 10;

  columns = [];

  partId = null;

  filters = [
    { value: 2, label: '最新' },
    { value: 1, label: '最热' },
  ];

  sortType = 2;

  mounted() {
    this.load_part();
    this.load(this.partId, this.sortType, true);
  }

  load_part() {
    this.loading += 1;
    post(
      `web_platform/getCommonArticlePart`,
      {},
      (data, res) => {
        this.loading -= 1;
        this.columns = data.data;
      },
      (err) => {
        this.loading -= 1;
      }
    );
  }

  load(partId, sortType, clearArticle) {
    this.partId = partId;
    this.sortType = sortType;

    if (!!this.loading && !this.hasmore) {
      return;
    }

    if (clearArticle) {
      this.articles = [];
    }

    this.loading += 1;
    post(
      `web_platform/listPlatformArticle`,
      {
        page: { from: this.articles.length, size: this.size },
        params: { partId, sortType },
      },
      (data, res) => {
        this.loading -= 1;
        const list = (data && data.data) || [];
        list.forEach((item) => {
          item.day = item.webPublishTime
            ? moment(item.webPublishTime).format("YYYY.MM.DD")
            : null;
          this.articles.push(item);
        });
        this.hasmore = list.length === this.size;
      },
      (err) => {
        this.loading -= 1;
      }
    );
  }
}
</script>

<style scoped lang="less">
.articles {
  background-color: white;
  padding: 0 3rem 3rem;
  font-size: 1.3rem;

  .article {
    .time-line {
      width: 3rem;

      .time-line-dot {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        box-sizing: border-box;
        border: 4px solid #ccc;
      }

      .time-line-line {
        width: 2px;
        flex: 1;
        background-color: #ccc;
      }
    }

    .content {
      flex: 1;
      width: 10px;
      margin: -0.42rem 0 0.42rem 0;

      .article-cover {
        width: 14rem;
        height: 8rem;
        object-fit: cover;
      }

      .title {
        width: 10px;
        flex: 1;
        word-break: break-all;
        margin-left: 1em;
        line-height: 1.7;
      }
    }
  }
}

.color_a5 {
  color: #a5a5a5;
}

.color_f3 {
  color: #f39801;
}

.loadmore {
  cursor: pointer;
  line-height: 2;
  margin: 1rem;
  border: 1px solid #00a0ea;
  border-radius: 3px;
  color: #00a0ea;
  padding: 0 0.8rem;
}

.loading {
  border-color: #999;
  color: #999;
  cursor: default;
}

.nomore {
  color: #999;
  border: 0;
  cursor: default;
}
</style>